/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-600italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-600italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-600italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-600italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-600italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-600italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-700italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/open-sans-v18-latin-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v18-latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v18-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v18-latin-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v18-latin-700italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* fjalla-one-regular - latin */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/fjalla-one-v8-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/fjalla-one-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/fjalla-one-v8-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/fjalla-one-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/fjalla-one-v8-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne') format('svg');
  /* Legacy iOS */
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-slab-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/roboto-slab-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-slab-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-slab-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-slab-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab') format('svg');
  /* Legacy iOS */
}

/* roboto-slab-600 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto-slab-v13-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/roboto-slab-v13-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-slab-v13-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-slab-v13-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-slab-v13-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab') format('svg');
  /* Legacy iOS */
}

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/roboto-slab-v13-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../fonts/roboto-slab-v13-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto-slab-v13-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto-slab-v13-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/roboto-slab-v13-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab') format('svg');
  /* Legacy iOS */
}

/* montserrat-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/montserrat-v25-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-300.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/montserrat-v25-latin-300italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-300italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-regular.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-latin-italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v25-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-500.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/montserrat-v25-latin-500italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-500italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v25-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-600.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/montserrat-v25-latin-600italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-600italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-700.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-latin-700italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-700italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/montserrat-v25-latin-800.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/montserrat-v25-latin-800.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* material icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/MaterialIcons-Regular.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  // -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/MaterialIconsOutlined-Regular.otf') format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  // -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/MaterialIconsRound-Regular.otf') format('woff2');
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  // -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: sfs;
  src: url('https://cdn.statefoodsafety.com/fonts/sfs/sfs.ttf?dbxike') format('truetype'),
    url('https://cdn.statefoodsafety.com/fonts/sfs/sfs.woff?dbxike') format('woff'),
    url('https://cdn.statefoodsafety.com/fonts/sfs/sfs.svg?dbxike#sfs') format('svg');
  font-style: normal;
  font-weight: normal;
}

[class*=' sfs-icon-'],
[class^='sfs-icon-'] {
  &::before {
    font-family: sfs !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none;
    speak: none;
    -webkit-font-feature-settings: 'liga';
    -moz-font-feature-settings: 'liga=1';
    -moz-font-feature-settings: 'liga';
    -ms-font-feature-settings: 'liga' 1;
    font-feature-settings: 'liga';
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
  }
}
.sfs-icon-shield {
  &:before {
    font-size: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    content: '';
  }
  &.food-handler {
    &:before {
      color: #f20808;
    }
  }
  &.food-manager {
    &:before {
      color: #0075a2;
    }
  }
  &.alcohol {
    &:before {
      color: #71c8f0;
    }
  }
  &.food-allergens {
    &:before {
      color: #934a9c;
    }
  }
}
