/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #333333;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #3d3d3d;
  color: #fff;
  // border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #fff;
    border-bottom-width: 0;
    &:hover {
      border-bottom-width: 1px;
    }
  }
  .social a {
    border: 0;
  }
  ul.carrot {
    li {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }
}

/* --------- asc styles --------- */
#header-wrap {
  background: #545454;
}
#header {
  a.logo {
    border: 0;
    width: 80%;
    max-width: 48px;
    img.full-logo {
      max-width: 226px;
    }
    img.icon-logo {
      max-width: 48px;
    }
  }
  .nav-icon {
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    border: 0;
    text-align: center;
    &:hover {
      background-color: lighten($theme-primary, 7.5%);
    }
    .material-icons {
      display: inline-block;
      vertical-align: middle;
      font-size: 30px;
      line-height: 50px;
    }
    &.cart.has-product {
      position: relative;
      i:before {
        right: 0;
        top: 0;
        height: 20px;
        width: 20px;
        font-size: 14px;
        line-height: 20px;
        content: attr(data-qty);
        font-family: var(--font-family);
        position: absolute;
        color: #fff;
        background-color: #44b649;
        border-radius: 20px;
        z-index: 999;
        text-align: center;
      }
    }
  }
  .nav-login {
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
    min-width: 70px;
    border: 0;
    text-decoration: none;
    background-color: $theme-fh;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    &:hover {
      background-color: darken($theme-fh, 10%);
    }
  }
  #support {
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
.editor-dynamic-code {
  * {
    font-family: inherit !important;
    line-height: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    text-decoration: inherit !important;
  }
  [style*='color'],
  font[color] {
    color: inherit !important;
  }
  [style*='font-size'] {
    font-size: inherit !important;
  }
  [style*='font-family'] {
    font-family: inherit !important;
  }
  [style*='line-height'] {
    line-height: inherit !important;
  }
  div:last-child {
    br:only-child {
      display: none;
    }
  }
}
.auth-user {
  .user-btn {
    padding: 0;
    width: 38px;
    height: 38px;
    color: #fff;
    background-color: $theme-fh;
    border: 0;
    &:after {
      display: none;
    }
    &:hover {
      background-color: darken($theme-fh, 10%);
    }
  }
  .dropdown-menu {
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    a {
      border: 0;
    }
    .dropdown-header {
      color: $theme-fh;
      &:hover {
        background-color: var(--bs-dropdown-link-hover-bg);
      }
    }
    .user-initials {
      padding: 0;
      width: 38px;
      height: 38px;
      line-height: 37px;
      border: 1px solid $theme-fh;
    }
  }
}

.authenticated-content-block {
  display: none !important;
}
.authenticated-content-inline {
  display: none !important;
}
.authenticated-content-flex {
  display: none !important;
}
.authenticated {
  .unauthenticated-content-block {
    display: none !important;
  }
  .unauthenticated-content-inline {
    display: none !important;
  }
  .unauthenticated-content-flex {
    display: none !important;
  }
  .authenticated-content-block {
    display: block !important;
  }
  .authenticated-content-inline {
    display: inline-block !important;
  }
  .authenticated-content-flex {
    display: flex !important;
  }
}

// .custom-tooltip {
//   --bs-tooltip-bg: #ffffff;
//   --bs-tooltip-color: #333333;
// }
#banner-wrap {
  background-color: #f7f7f7;
  &.overlay {
    position: relative;
    background-color: darken($theme-primary, 10%);
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.45;
      background-color: #000;
    }
  }
  #banner {
    position: relative;
    h1 {
      line-height: 1.2em;
    }
    a {
      color: inherit;
      // text-decoration: underline;
      // border-bottom: 0;
      // &:hover {
      //   color: #337ab7;
      // }
    }
    .buy-cta {
      border-color: $theme-fm !important;
      background-color: #fff;
    }
    .add-to-cart-btn {
      width: 100%;
      max-width: 225px;
    }
    .product-icon {
      width: 60px;
      height: auto;
    }
    .subtitle {
      border-top: 1px dotted rgba($color: #ffffff, $alpha: 0.75);
      .seal-logo {
        width: 60px;
        height: auto;
      }
      a {
        color: inherit;
        font-size: inherit !important;
      }
      h2,
      h3,
      h4 {
        color: inherit !important;
        font-size: 1rem;
      }
    }
    .dropdown-list {
      min-width: 225px;
      max-width: 350px;
      .cta-dropdown {
        background-color: $theme-fh;
        color: #fff;
        border-radius: var(--bs-border-radius);
        padding: 7px 18px;
        height: auto;
        font-size: 18px;
        border: 0;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
      }
      .drop {
        top: 45px;
        border-color: $theme-fh;
      }
    }
    .hpg-btn {
      min-width: 285px;
      max-width: 425px;
      text-decoration: none;
      text-transform: capitalize !important;
      &.bg-theme-fh:hover {
        background-color: darken($theme-fh, 5%) !important;
      }
      &.bg-theme-fm:hover {
        background-color: darken($theme-fm, 5%) !important;
      }
      &.bg-theme-fa:hover {
        background-color: darken($theme-fa, 5%) !important;
      }
      &.bg-theme-as:hover {
        background-color: darken($theme-as, 5%) !important;
      }
      &.bg-theme-haccp:hover {
        background-color: darken($theme-haccp, 5%) !important;
      }
    }
  }
}
#log-in-modal {
  .modal-content {
    background-color: darken($theme-primary, 10%);
    .icon-close {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #fff;
      background: inherit;
      border: 0px;
      width: fit-content;
      padding: 8px;
    }
    .icon-close:hover {
      background-color: lighten($theme-primary, 5%);
      border-radius: 50%;
    }
    .icon-close::before {
      height: 20px;
      width: 20px;
    }
    .modal-body {
      .person-icon,
      .lock-icon {
        position: absolute;
        top: 10px;
        left: 5px;
      }
      .form-control {
        padding: 10px 20px 10px 35px;
      }
      .form-control::placeholder {
        color: $theme-primary !important;
      }
      a {
        color: #fff;
        font-weight: 200 !important;
        &:hover {
          color: darken(#fff, 10%);
        }
      }
    }
  }
}
#icons-modal,
.white-x {
  .icon-close {
    position: absolute;
    right: 5px;
    top: 3px;
    color: #fff;
    background: inherit;
    border: 0px;
    width: fit-content;
    padding: 8px;
  }
  .icon-close:hover {
    background-color: #fff;
    color: #333;
    border-radius: 50%;
  }
  .icon-close::before {
    height: 20px;
    width: 20px;
  }
}

.separator-bottom {
  position: relative;
  padding-bottom: 1.5rem;
  &::after {
    content: '';
    position: absolute;
    width: 150px;
    height: 2px;
    background-color: $theme-fh;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.hd-fee-video-btn {
  .icon-youtube {
    &:before {
      font-size: 36px;
    }
  }
}
.arrow-box {
  cursor: pointer;
  max-width: 300px;
  .number {
    min-width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    border: 1px solid $theme-fh;
    color: $theme-fh;
    border-radius: 50%;
    font-weight: 600;
  }
  ul {
    @extend .check;
    li {
      list-style-type: none !important;
      &::marker {
        display: none;
      }
      &::before {
        color: $theme-fh;
      }
    }
  }
}
.icon-col {
  .icon-box {
    cursor: pointer;
    max-width: 240px;
  }
  .icon-img {
    max-width: 60px;
  }
}
.crossed-out-price {
  position: relative;
  &::after {
    display: block;
    content: '';
    width: 110%;
    height: 2px;
    background: currentColor;
    position: absolute;
    top: 49%;
    left: -5%;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    -webkit-transform-origin: 40% 0;
    transform-origin: 40% 0;
    opacity: 0.75;
  }
}
.upsell-card {
  border-color: #909090;
  .card-header {
    padding: 25px 10px 10px 10px;
    border: 0;
    background-color: #909090;
    color: #909090;
    // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    .sfs-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid currentColor;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: inherit;
    }
  }
  &.food-handler {
    border-color: $theme-fh;
    .card-header {
      background-color: $theme-fh;
      color: $theme-fh;
    }
  }
  &.food-manager {
    border-color: $theme-fm;
    .card-header {
      background-color: $theme-fm;
      color: $theme-fm;
    }
  }
  &.allergens {
    border-color: $theme-fa;
    .card-header {
      background-color: $theme-fa;
      color: $theme-fa;
    }
  }
  &.alcohol-server {
    border-color: $theme-as;
    .card-header {
      background-color: $theme-as;
      color: $theme-as;
    }
  }
}
.summary-box {
  border-top: 3px solid $theme-fh !important;
}
.why-icon {
  max-width: 150px;
}
.accordion-tabs {
  .accordion-button {
    color: inherit;
    box-shadow: none !important;
    border-color: var(--bs-accordion-border-color);
    &:not(.collapsed) {
      background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
      border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
      &::after {
        background-image: var(--bs-accordion-btn-icon);
      }
    }
  }
  .nav-link {
    height: calc(100% + 1px);
  }
}
.proctor-card {
  border-color: $theme-fm;
}
.prerequesite-item {
  .editor-dynamic-code {
    display: inline;
  }
}
.purchase-cards {
  background-color: #f7f7f7;
  .card {
    box-shadow: #d8d8d8 6px 6px;
    .cta-btn {
      min-width: 200px;
    }
  }
}
.guarantees,
.courses,
.resources {
  background-color: $theme-fm;
  .cta-btn {
    max-width: 232px;
  }
}
.guarantees:hover {
  cursor: pointer;
}
.overlay-description {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  backdrop-filter: blur(5px);
  .cta-btn {
    width: 232px;
  }
}
.product-tile:hover .overlay-description {
  opacity: 1;
}

.icon-heading {
  background: $theme-fh;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  vertical-align: middle;
  span {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
.regulatory-banner {
  background: #fff url(../images/regulatory-food-safety-resources.jpg) no-repeat center/cover;
  .regulatory-content {
    background-color: $theme-fm;
    color: #fff;
    h2 {
      color: #fff;
    }
  }
  .cta-btn {
    max-width: 232px;
  }
}
.haccp-retail-banner {
  background: #f7f7f7 url(../images/banners/haccp-retail-banner-bg-sm.jpg) 50% 8% / cover;
}
.homepage-sfs-link {
  color: #333 !important;
}
.location-table {
  min-height: 450px;
}
.fm-product-table-wrap {
  .table-row-size {
    width: 80%;
  }
  .icon-check {
    font-size: 1.25em;
  }
  .fm-product-column > div,
  .four-column > div {
    &.fm-state-table-details {
      a {
        color: #333 !important;
        &:hover {
          color: lighten(#555, 7.5%) !important;
        }
      }
    }
  }

  .end-row {
    min-height: 70px;
    background: #ddebf0 !important;
  }
  .first-table-column {
    border-color: transparent !important;
    border-bottom-color: #cfd5db !important;
    background: transparent !important;
  }
  .last-table-column {
    border-color: transparent !important;
    border-top-color: #cfd5db !important;
    background: transparent !important;
  }
  // .border-theme-fm {
  //   border-color: $theme-fm !important;
  // }
  .fm-product-column,
  .four-column {
    > div {
      border-left: #cfd5db 2px solid;
      border-top: #cfd5db 1px solid;
      border-bottom: #cfd5db 1px solid;
      border-right: #cfd5db 2px solid;
      &:first-child {
        border-top: #cfd5db 2px solid;
      }
      &:last-child {
        border-bottom: #cfd5db 2px solid;
      }
      &:nth-child(odd) {
        background: #f8f9fa;
      }
    }
  }
  .fm-product-column:last-child > div,
  .four-column:last-child > div {
    border-right: #cfd5db 2px solid !important;
  }
}
.customer-reviews {
  > div {
    &:nth-child(n) {
      border-bottom: #ccc 1px solid;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
.star-value {
  min-width: 55px;
}
.star-bar {
  background: #ccc;
}
.star-percentage {
  min-width: 41px;
}
#practice-test {
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    p {
      margin-bottom: 0;
    }
    &.green {
      background-color: #e9ffdb;
      border: 1px solid #6cb142;
      color: #4e8c28;
    }
    &.red {
      background-color: #ffeeee;
      border: 1px solid #ffabab;
      color: #cc0000;
    }
  }
}
.mw-50 {
  max-width: 50% !important;
}
.quote {
  font-family: 'georgia', 'serif';
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #banner-wrap {
    #banner {
      .hpg-btn {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
      }
    }
  }
  .location-table {
    min-height: 345px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img.full-logo {
      max-width: 226px;
    }
    img.icon-logo {
      max-width: 48px;
    }
    span.big {
      font-size: 1.9em;
    }
  }
  #banner-wrap {
    #banner {
      .product-icon {
        width: 105px;
      }
      .subtitle {
        .seal-logo {
          width: 75px;
        }
      }
    }
  }
  .fm-product-table-wrap {
    .fm-product-column > div,
    .four-column > div {
      &.package-options,
      &.product-title-box {
        min-height: 85px;
      }
      &.fm-state-table-details {
        min-height: 90px;
      }
    }
  }
}
// roughly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// just for the accordion tabs DO NOT use this breakpoint for other styles
@media (max-width: 991px) {
  .accordion-tabs {
    .tab-content > .tab-pane {
      display: block;
      opacity: 1;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .arrow-box {
    background-color: $theme-fm;
    color: #fff;
    border-radius: 0.375rem;
    .number {
      border-color: #fff;
      background-color: #fff;
      color: $theme-fh;
    }
    h3 {
      color: #fff;
    }
    ul {
      width: calc(100% + 25px);
      margin-left: -23px;
      li {
        &::before {
          color: #fff;
        }
      }
    }
  }
  .icon-col {
    .icon-img {
      max-width: 80px;
    }
  }
  .upsell-card-col {
    max-width: 330px;
  }
  .accordion-tabs {
    .nav-link {
      border-color: var(--bs-nav-tabs-link-hover-border-color);
      &.active {
        background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
        border-bottom: 1px solid rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
      }
    }
    .accordion-item {
      border-top: 0;
      border-radius: 0 0 var(--bs-accordion-border-radius) var(--bs-accordion-border-radius);
      background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    }
  }
  .regulatory-banner {
    background: #fff url(../images/banners/regulatory-food-safety-resources-banner.jpg) no-repeat center/cover;
    .regulatory-content {
      background-color: transparent !important;
      color: #333;
      h2 {
        color: #333 !important;
      }
    }
  }
  .haccp-retail-banner {
    background: #f6f6ee url(../images/banners/haccp-retail-banner-bg.jpg) no-repeat 50% 19% / cover;
    &.box {
      background: #fff;
    }
  }
  .fm-product-table-wrap {
    .fm-product-column > div {
      border-right: 0;
    }
    .fm-product-column > div,
    .four-column > div {
      min-height: 65px;
    }
  }
  .mw-lg-100 {
    max-width: 100% !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 226px;
  }
  .arrow-box {
    border-radius: 0;
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    &.first-box {
      border-radius: 0.375rem 0 0 0.375rem;
      clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
    }
  }
  .arrow-box {
    border-radius: 0;
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    &.first-box {
      border-radius: 0.375rem 0 0 0.375rem;
      clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
    }
  }
  .fm-product-table-wrap {
    .four-column > div {
      border-right: 0;
    }
    .icon-check {
      font-size: 1.5em;
    }
  }
}

@media (min-width: 1400px) {
  #header {
    .nav-icon {
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      border: 0;
      text-align: center;
      &:hover {
        background-color: lighten($theme-primary, 7.5%);
      }
      .material-icons {
        display: inline-block;
        vertical-align: middle;
        font-size: 21px;
        line-height: 40px;
      }
    }
  }
}
